$background-color: #FCF5EB ;
$primary-color: #E60004;
$secondary-color: var(--bs-warning);
$success-color: #23C58F;
$danger-color: #f14E4E;
$white-color: #FFF;
$black: #000;
$color-input: #495057;
$color-border: #EDF1F5;
$color-border-input: #CED4DA;
$color-background-input-disabled: #EDF1F5;
$sidebar-menu-item-active-color: $primary-color;
$table-striped-odd-bg: #F5F7FA;