//
// avatar.scss
//


.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.mini-stat-icon{

  width: 46px;
  height: 46px;

}

.avatar-title {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}