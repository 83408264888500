@import 'variables';

body {
  background-color: $background-color !important;
}
.authentication-bg {
  background-image: url(../images/login-background.jpg) !important;
  overflow: hidden;

  .bg-overlay {
    background-color: $black !important;
  }
}

.page-title-box {
  background-image: url(../images/background.jpg) !important;
}

.header-profile-user {
  background-color: $primary-color !important;
}

.user-sidebar .user-img img {
  border-color: $secondary-color;
}

#sidebar-menu {
  ul {
    li {
      a {
        &:hover {
          color: $sidebar-menu-item-active-color !important;
          i { color: $sidebar-menu-item-active-color !important; }
        }
      }
    }
  }
  .active {
    color: $sidebar-menu-item-active-color !important;
    i { color: $sidebar-menu-item-active-color !important; }
  }
  .mm-active {
    > a {
      color: $sidebar-menu-item-active-color !important;
      i { color: $sidebar-menu-item-active-color !important; }
    }
    a.active {
      color: $sidebar-menu-item-active-color !important;
      i { color: $sidebar-menu-item-active-color !important; }
    }
  }
}

.select2-dropdown {
  z-index: 9999;
}
/* This two selectors fix the multiple select placeholder issue. SEE: https://stackoverflow.com/a/61059820 */
.select2-search--inline {
  display: contents; /*this will make the container disappear, making the child the one who sets the width of the element*/
}

.select2-search__field:placeholder-shown {
  width: 100% !important; /*makes the placeholder to be 100% of the width while there are no options selected*/
}

.reorder {
  cursor: move;
}

.swal2-container {
  z-index: 9999 !important;
}

.dataTables_scroll {
  overflow:auto;
}

.table-row-success {
  background-color: #dff0d8;
}

.table-row-warning {
  background-color: #fcf8e3;
}

#loading{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%
  }
}