@charset "UTF-8";

$primary: #E60004;
$secondary: #757575;
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);

a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
}